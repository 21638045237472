
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '../../components/SubModule.vue';
import routes from './routes';
import store from './store';
import { EntityStoreGetters } from '@/base';
import { IMenuItem } from '../../components/Menu.vue';

@Component({

})
export default class Report extends SubModule
{
  private get activeItem(): typeof store[EntityStoreGetters.ACTIVE_ITEM]
  {
    return store[EntityStoreGetters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    const activeItem = this.activeItem;
    const items: IMenuItem[] = [];
    if (activeItem)
    {
      if (activeItem.$isCreated())
      {
        items.push(
          {
            title: this.$t('enums.ReportState.' + activeItem.$original.reportState) + ' ' + activeItem.id,
            icon: `$vuetify.icons.edit`,
            route: `/report/${activeItem.id}`,
          },
        );

      }
      else
      {
        items.push({
          title: this.$t('form.titleNew').toString(),
          icon: `$vuetify.icons.add`,
          route: `/report/new`,
        });
      }
    }

    return items;
  }
}
